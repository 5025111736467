import { Content, Header, Page } from '@backstage/core-components';
import { useRouteRefParams, identityApiRef, useApi, ProfileInfo } from '@backstage/core-plugin-api';
import React, { useEffect, useState } from 'react';
import { detailRouteRef } from '../../routes';
import { Assistant, assistantsByName } from '../../data';

export const AssistantDetailComponent = () => {
  const [profile, setProfile] = useState<ProfileInfo>();
  const { assistantName }     = useRouteRefParams(detailRouteRef);
  const identity              = useApi(identityApiRef);
  const assistant: Assistant  = assistantsByName[assistantName];
  const AssistantComponent    = assistant.component;

  useEffect(() => {
    const doRequest = async () => {
      const profileInfo = await identity.getProfileInfo();
      setProfile(profileInfo);
    };

    doRequest();
  }, [identity]);
  
  return (
    <Page themeId='tool'>
      <Header title={assistant.title}
              subtitle={assistant.description} />
      
      <Content>
        {
          AssistantComponent === undefined || AssistantComponent === null
          ? <div>Coming Soon</div>
          : <AssistantComponent profile={profile} />
        }
      </Content>
    </Page>
  );
};