import { useEffect, useState } from "react";
import { useApi, configApiRef, fetchApiRef } from "@backstage/core-plugin-api";

const iamHubBackendBasePath = '/api/iam-hub';

class ApiResource {
  readonly baseUrl: string;
  private readonly fetch: Function;

  constructor(baseUrl: string, fetch: Function) {
    this.baseUrl  = baseUrl;
    this.fetch    = fetch;
  }

  async get(recordId?: string): Promise<any> {
    const url = this.getInstanceUrl(recordId);
    const response = await this.fetch(url);

    return await response.json();
  }

  async list(): Promise<any> {
    const response = await this.fetch(this.baseUrl);
    return await response.json();
  }

  async create(bodyParams?: BodyInit): Promise<any> {
    const response = await this.fetch(this.baseUrl, { method: 'POST', body: bodyParams });
    return await response.json();
  }

  async update(recordId?: string, bodyParams?: BodyInit): Promise<any> {
    const url = this.getInstanceUrl(recordId);
    const response = await this.fetch(url, { method: 'PUT', body: bodyParams });

    return await response.json();
  }

  async destroy(recordId?: string): Promise<any> {
    const url = this.getInstanceUrl(recordId);
    const response = await this.fetch(url, { method: 'DELETE' });

    return await response.json();
  }

  private getInstanceUrl(recordId?: string): string {
    return  recordId === undefined 
            ? this.baseUrl 
            : `${this.baseUrl}/${recordId}`;
  }
}

function useIamHub() {
  const config      = useApi(configApiRef);
  const { fetch }   = useApi(fetchApiRef);
  const backendUrl  = config.getString('backend.baseUrl');
  const baseUrl     = `${backendUrl}${iamHubBackendBasePath}`;

  return { baseUrl, fetch };
}

function createEndpointHook<T>(pathBuilder: (pathParams: T) => string) {
  return (pathParams: T) => {
    const { baseUrl, fetch }  = useIamHub();
    const path                = pathBuilder(pathParams);
    const baseEndpointUrl     = `${baseUrl}${path}`

    return new ApiResource(baseEndpointUrl, fetch);
  };
}

type UserApiParams = { 
  endpointSlug: string;
};

type MembershipApiParams = {
  endpointSlug: string;
  email: string
};

export const useIamHubUserApi = createEndpointHook<UserApiParams>(({ endpointSlug }) => (
  `/endpoints/${endpointSlug}/users`
));

export const useIamHubMembershipApi = createEndpointHook<MembershipApiParams>(({ endpointSlug, email }) => (
  `/endpoints/${endpointSlug}/users/${email}/memberships`
));

